import React from "react";
import Link, { withPrefix } from "gatsby-link";

const Header = () => (
  <div>
    {/*  loader  */}
    <div id="myloader" style={{ display: "none" }}>
      <div className="loader">
        <div className="grid">
          <div className="cube cube1" />
          <div className="cube cube2" />
          <div className="cube cube3" />
          <div className="cube cube4" />
          <div className="cube cube5" />
          <div className="cube cube6" />
          <div className="cube cube7" />
          <div className="cube cube8" />
          <div className="cube cube9" />
        </div>
      </div>
    </div>
    {/*  Header & Menu  */}
    <header id="header">
      <div className="top-nav">
        {/*  Header Logo  */}
        <div id="logo">
          <Link className="navbar-brand" to="/">
            <img
              src={withPrefix("/assets/img/logo.png")}
              className="normal"
              alt="logo"
            />
            <img
              src={withPrefix("/assets/img/logo@2x.png")}
              className="retina"
              alt="logo"
            />
          </Link>
        </div>
        {/*  END Header Logo  */}
        <div className="secondary-menu">
          <ul>
            <li className="mail">
              <i className="fa fa-envelope" aria-hidden="true" />
              <a href="maito:info@singo.mo">info@singo.mo</a>
            </li>
            {/**
                        <li className="lang">
                            <span className="current"><a href="#">En</a></span>
                            <ul>     
                                <li><a href="#">It</a></li>
                            </ul>
                        </li>
                       
                        {/ * Search Icon * /}
                        <li className="search">
                            <i className="fa fa-search" aria-hidden="true"></i>
                        </li>
                        */}
          </ul>
        </div>
      </div>
      <nav className="navbar navbar-default">
        {/*  Classic menu, responsive menu classic  */}
        <div id="menu-classic">
          <div className="menu-holder">
            <ul>
              <li>
                <Link to="/">主頁</Link>
              </li>
              <li>
                <Link to="/aboutus">公司簡介</Link>
              </li>
              <li>
                <Link to="/blog">最近動態</Link>
              </li>
              {/* <li>
                                <a href="services.html">Services</a>
                            </li> */}
              <li>
                <Link to="/guard">天機1號</Link>
              </li>
              <li>
                <Link to="/butler">小馬哥1號</Link>
              </li>
              {/*                            
                            <li>
                                <a href="/contactus">聯絡我們</a>
                            </li> */}
            </ul>
          </div>
        </div>
        {/*  END Classic menu, responsive menu classic  */}
        {/*  Button for Responsive Menu Classic  */}
        <div id="menu-responsive-classic">
          <div className="menu-button">
            <span className="bar bar-1" />
            <span className="bar bar-2" />
            <span className="bar bar-3" />
          </div>
        </div>
        {/*  END Button for Responsive Menu Classic  */}
        <div className="secondary-menu-mobile">
          <ul>
            <li className="mail">
              <i className="fa fa-envelope" aria-hidden="true" />
              <a href="maito:info@singou.mo">info@singou.mo</a>
            </li>
          </ul>
        </div>
      </nav>
      <div id="header-searchform">
        <form className="search-form">
          <div className="form-input">
            <input type="text" placeholder="Search..." />
            <span className="form-button-close">
              <button type="button">
                <i className="material-icons">close</i>
              </button>
            </span>
            <span className="form-button">
              <button type="button">Search</button>
            </span>
          </div>
        </form>
      </div>
    </header>
    {/*  END Header & Menu  */}
  </div>
);

export default Header;
