import React, { Component } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import Link from "gatsby-link";
import "../pages/index/index.less";

import  Wordpress77  from "./wordpress77"
import {pages_transform,page_transform} from "../utils/pageutil"
import Layout from "../components/layout"


class PostTemplate extends Component {
  render() {
    
    const post = page_transform(this.props.data.markdownRemark);
    const seed = { target:  post.post_id, tmp_last_id: null };
    const pair_posts = pages_transform(this.props.data).reduce(
      (a, node) => {
        const cur_id = node.post_id;
        const cur_title = node.title;
        if (a.target === cur_id) {
          a.prev_id = a.tmp_last_id;
          a.prev_title = a.tmp_last_title;
        }
        if (a.tmp_last_id === a.target) {
          a.next_id = cur_id;
          a.next_title = cur_title;
        }
        a.tmp_last_id = cur_id;
        a.tmp_last_title = cur_title;
        return a;
      },
      seed
    );

    return (
      <Layout>
      <div id="main-wrap">
        {" "}
        {/* Main Wrap  */}
        {/* Page Content  */}
        <div id="page-content" className="header-static">
          <div id="home-wrap" className="content-section fullpage-wrap">
            <div className="container">
              {/*News */}
              {/* News Section  */}
              <section id="news" className="page">
                {/*Single News */}

                {/*END Single News */}
                <div className="row no-margin padding-lg">
                  <div className="col-md-12 padding-leftright-null">
                    <div className="text padding-topbottom-null">
                      {post.featured_media && post.featured_media.childImageSharp ? (
                        <Img
                          className="limit-img-height"
                          sizes={
                            post.featured_media.childImageSharp.sizes
                          }
                          alt=""
                        />
                      ) : null}
                      <br />
                      <h3>{post.title}</h3>
                      <br />
                        <div id="singoublogcontentdiv">
                          <p class="singoublogcontent" dangerouslySetInnerHTML={{ __html: post.body }} />
                        </div>
                      { post.post_id === "77" ? <Wordpress77/> : null }
                      <span className="date">{post.date}</span>
                      {/* <div className="author padding-onlytop-sm">
                        <span>
                          Posted by:
                          <a href="">Admin</a>
                        </span>
                      </div>
                      <div className="tag">
                        <span>
                          Tags:
                          <a href="">App</a>,
                          <a href="">Design</a>,
                          <a href="">Creative</a>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
              {/*END News */}

              {/* Navigation  */}
              <section id="nav" className="padding-top-null">
                <div className="row">
                  <div className="col-xs-6">
                    {pair_posts.prev_id ? (
                      <div className="nav-left">
                        <Link
                          to={"/blog-" + pair_posts.prev_id + ".html"}
                          className="btn-alt small shadow margin-null"
                        >
                          <i className="icon ion-ios-arrow-left" />
                          <span>{pair_posts.prev_title}</span>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-xs-6">
                    {pair_posts.next_id ? (
                      <div className="nav-right">
                        <Link
                          to={"/blog-" + pair_posts.next_id + ".html"}
                          className="btn-alt small shadow margin-null"
                        >
                          <span>{pair_posts.next_title}</span>
                          <i className="icon ion-ios-arrow-right" />
                        </Link>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
              {/* END Navigation  */}
            </div>
          </div>
        </div>
        {/* END Page Content */}
        {/* Main Wrap  */}
      </div>
      </Layout>
    );
  }
}
//<img src={post.image.sizes.thumbnail} />

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export default PostTemplate;


export const pageQuery = graphql`
query currentPostQuery($slug: String = "/1/" ) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    ... pageFields
  }

  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }){
    edges{
      node{
      	... pageFields
      }
    }
  }
  
  site {
    siteMetadata {
      title
    }
  }
}
`;

// wordpressPost(wordpress_id: { eq: $wordpress_id }) {
//   title
//   content
//   id
//   slug
//   wordpress_id
//   featured_media {
//     localFile {
//       childImageSharp {
//         sizes: sizes(maxWidth: 800, maxHeight: 450, cropFocus: CENTER) {
//           ...GatsbyImageSharpSizes_withWebp
//         }
//         largeResolutions: resolutions(width: 1000, height: 600) {
//           ...GatsbyImageSharpResolutions_withWebp
//         }
//         thumbnailResolutions: resolutions(width: 257, height: 155) {
//           ...GatsbyImageSharpResolutions_withWebp
//         }
//       }
//     }
//   }
//   date(formatString: "YYYY年MMMD日", locale: "zh-TW")
// }