import React from "react";
import  { withPrefix } from "gatsby-link";

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row no-margin">
        <div className="col-md-2 text">
          <div className="logo">
            <img
              src={withPrefix("/assets/img/logo_white.png")}
              className="normal"
              alt="logo"
            />
            <img
              src={withPrefix("/assets/img/logo_white@2x.png")}
              className="retina"
              alt="logo"
            />
          </div>
        </div>
        <div className="col-md-3 text small">
          <h4 className="heading white margin-bottom-small ">
            地址及服務時間
          </h4>
          <p>
          <span>安信通科技（澳門）有限公司</span><br/>
          <span>Singou  Technology  (Macau)  Ltd.</span><br/>澳門卑第巷18號百麗大廈地下B座<br/>
          Travessa do Padre Narciso No. 18. Pak Lat Res-Do-Chao B. Macau<br/>
          Tel: +853  28782984<br/>
          Fax:  +853  28782985
          </p>
          <br/><br/>

   <p>
          <span>中山安信通機器人製造有限公司</span><br/>
          <span>Singou  Robotics  (ZhongShan)  Ltd.</span><br/>
          廣東省中山市中山港大道60號火炬職業技術學院內15棟504
          </p>


        </div>
        <div className="col-md-3 text small">
          <h4 className="heading white margin-bottom-small ">
          &nbsp;
          </h4>

          <p>
          <span>珠海全信通科技有限公司</span><br/>
          <span>Singou  Technology  (Zhuhai)  Ltd.</span><br/>
          廣東省珠海市橫琴新區寶華路6號105室-34312
          <br/>
          Tel: +86 756-2990710
          </p><p><br/>
          <span>澳門天機1號服務機器人深圳研發中心</span><br/>
          <span>Singou  Guard  I  Shenzhen  Research  &  Development  Center</span><br/>
          深圳市南山區深圳灣科技生態園二區9棟A2302<br/>
          Room 2302, Building 9 A，District II, Shenzhen Bay Eco-Technology Park, Nanshan Dirstrict, Shenzhen City
          </p>




        </div>
        <div className="col-md-4 text">
          <h4 className="heading white margin-bottom-small weight-300">
            訂閱我們的通訊
          </h4>
          <div id="newsletter-form">
            <form
              className="search-form"
              action="https://singou.us17.list-manage.com/subscribe/post?u=aa9839a9b647568a1e5970d30&amp;id=b4f5787775"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate
            >
              <div className="form-input">
                <input type="email" placeholder="電郵" name="EMAIL" />
                <span className="form-button">
                  <button type="submit">訂閱</button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="copy">
      <div className="container">
        <div className="row no-margin">
          <div className="col-md-6 text">
            <p>
              2018 <a href="#nogo">Singou</a>. All rights reserved.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
